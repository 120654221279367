var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Footer"},[_c('div',{staticClass:"homePage6"},[_vm._m(0),_c('div',{staticClass:"page6Box connectBoxW"},[_c('div',[_c('div',{staticClass:"boxLeft"},[_c('div',{staticClass:"footer-logo-box"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("@/assets/logos/headerLogo.png"),"alt":""}})])],1),_c('p',[_vm._v("SpaceFi is the DeFi hub on zk Rollups with DEX+NFT+Spacebase+Launchpad, exploring the Layer2 ecosystem.")]),_vm._m(1)]),_c('div',{staticClass:"boxRight"},[_c('ul',[_vm._m(2),_vm._m(3),_vm._m(4),_c('li',[_c('router-link',{attrs:{"to":"/Farm","tag":"a"}},[_vm._v("Farm")])],1),_c('li',[_c('router-link',{attrs:{"to":"/Mint","tag":"a"}},[_vm._v("NFT")])],1),_c('li',[_c('router-link',{attrs:{"to":"/Spacebase","tag":"a"}},[_vm._v("Spacebase")])],1),_c('li',[_c('router-link',{attrs:{"to":"/xSPACE","tag":"a"}},[_vm._v("xSPACE")])],1),_c('li',[_c('router-link',{attrs:{"to":"/Launchpad","tag":"a"}},[_vm._v("Launchpad")])],1),_c('li',[_c('router-link',{attrs:{"to":"/Bridge","tag":"a"}},[_vm._v("Bridge")])],1),_c('li',[_c('router-link',{attrs:{"to":"/Points","tag":"a"}},[_vm._v("Points")])],1)]),_vm._m(5),_vm._m(6)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page6Bg"},[_c('img',{attrs:{"src":require("@/assets/Home/plantBottom.png"),"alt":"","srcset":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"linksList"},[_c('li',{staticClass:"externalLink"},[_c('a',{attrs:{"href":"https://twitter.com/spacefi_io","target":"_block"}},[_c('span')])]),_c('li',{staticClass:"externalLink"},[_c('a',{attrs:{"href":"https://discord.gg/nezjCa5j8r","target":"_block"}},[_c('span')])]),_c('li',{staticClass:"externalLink"},[_c('a',{attrs:{"href":"https://www.youtube.com/channel/UCg_vqttyVABAfhtfZJOPykg","target":"_block"}},[_c('span')])]),_c('li',{staticClass:"externalLink"},[_c('a',{attrs:{"href":"https://space-finance.medium.com/","target":"_block"}},[_c('span')])]),_c('li',{staticClass:"externalLink"},[_c('a',{attrs:{"href":"https://docs.spacefi.io/","target":"_block"}},[_c('span')])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"","onclick":"return false;"}},[_vm._v("PRODUCTS")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"https://swap-zksync-sepolia-testnet.spacefi.io/#/swap","target":"_self"}},[_vm._v("Swap")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"https://swap-zksync-sepolia-testnet.spacefi.io/#/pool","target":"_self"}},[_vm._v("Pool")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('a',{attrs:{"href":"","onclick":"return false;"}},[_vm._v("COMMUNITY")])]),_c('li',[_c('a',{attrs:{"href":"https://twitter.com/spacefi_io","target":"_block"}},[_vm._v("Twitter")])]),_c('li',[_c('a',{attrs:{"href":"https://discord.gg/nezjCa5j8r","target":"_block"}},[_vm._v("Discord")])]),_c('li',[_c('a',{attrs:{"href":"https://www.youtube.com/channel/UCg_vqttyVABAfhtfZJOPykg","target":"_block"}},[_vm._v("YouTube")])]),_c('li',[_c('a',{attrs:{"href":"https://space-finance.medium.com/","target":"_block"}},[_vm._v("Medium")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('a',{attrs:{"href":"","onclick":"return false;"}},[_vm._v("SUPPORT")])]),_c('li',[_c('a',{attrs:{"href":"https://docs.spacefi.io/","target":"_block"}},[_vm._v("Docs")])]),_c('li',[_c('a',{attrs:{"href":"https://drive.google.com/drive/folders/19CesXs5J8jv8GY3q-BmA5utbSQUPlD9g","target":"_block"}},[_vm._v("Media Kit")])]),_c('li',[_c('a',{attrs:{"href":"https://dexscreener.com/zksync/spacefi","target":"_block"}},[_vm._v("DEX Screener")])]),_c('li',[_c('a',{attrs:{"href":"https://www.geckoterminal.com/zksync/spacefi_zksync/pools","target":"_block"}},[_vm._v("GeckoTerminal")])]),_c('li',[_c('a',{attrs:{"href":"https://www.coingecko.com/en/coins/spacefi-evmos","target":"_block"}},[_vm._v("CoinGecko")])]),_c('li',[_c('a',{attrs:{"href":"https://coinmarketcap.com/currencies/spacefi-evmos/","target":"_block"}},[_vm._v("CoinMarketCap")])])])
}]

export { render, staticRenderFns }